import { Link, useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import Button from "react-bootstrap/Button";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import { BsFillCheckCircleFill } from "react-icons/bs";
import Hero from "../components/hero";

const SFTPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Specialist-Hero-image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			nhsImg: wpMediaItem(title: { eq: "Home-Hero-New" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gtImg: wpMediaItem(title: { eq: "Specialist-Crowd-image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			ftsImg: wpMediaItem(title: { eq: "FR-FTS-image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			nervImg: wpMediaItem(title: { eq: "Specialist-Image-3" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			WSIDImg1: wpMediaItem(title: { eq: "Specialist-image-5" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			WSIDImg2: wpMediaItem(title: { eq: "Specialist-Image-4" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			BAAImg1: wpMediaItem(title: { eq: "Specialist-Face-Image-1" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			BAAImg2: wpMediaItem(title: { eq: "Specialist-Face-Image-2" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			floppyImg: wpMediaItem(title: { eq: "Floppy" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const pluginImage1 = getImage(
		data.gtImg.localFile.childImageSharp.gatsbyImageData
	);
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Specialist Facial Therapy",
				item: {
					url: `${siteUrl}/specialist-facial-therapy`,
					id: `${siteUrl}/specialist-facial-therapy`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title="Specialist Facial Therapy | The Facial Rehabilitation Centre"
				description=""
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/specialist-facial-therapy`,
					title: "Specialist Facial Therapy | The Facial Rehabilitation Centre",
					description: "",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section>
					<Hero
						subTitle="Home > Specialist facial therapy"
						title="Specialist facial therapy"
						backgroundImage={
							data.heroImg?.localFile.childImageSharp.gatsbyImageData
						}
					/>
				</section>
				<section className="py-5 pt-md-0 pb-lg-7">
					<Container>
						<Row>
							<Col className="">
								<h2 className="text-dark-bg text-center mb-5">
									Why should I start facial therapy early?
								</h2>
								<p>
									There are many benefits to starting facial therapy early. When
									your nerve is not working or slowly recovering the face
									quickly develops incorrect movement habits. These can cause
									long term problems, in particular synkinesis. Research shows
									that early facial therapy can minimise the risk of synkinesis
									and improve your facial movement from a much earlier stage of
									recovery.
								</p>
								<p>
									{" "}
									Some people are on a waiting list for NHS treatment in the UK
									and like to have private treatment while they are waiting so
									they don’t miss out on the benefits of early facial therapy.
									They then transfer over the NHS when they reach the top of the
									waiting list. We are happy to work with you in this way.
								</p>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="pb-5 pb-lg-7">
					<Container>
						<Row>
							<Col className="">
								<h2 className="text-dark-bg text-center mb-5">
									What if I have had facial palsy for a long time? Can I be
									helped?
								</h2>
								<p>
									Everyone can benefit from specialist facial therapy. Whether
									you have had facial palsy for weeks, months, years, or even
									since birth, facial therapy can still help you greatly. We
									have treated patients who have had their facial palsy over 20
									years and still made excellent improvements.
								</p>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="py-5 py-lg-7 bg-dark-bg">
					<Container>
						<Row className="align-items-center gx-6">
							<Col lg={5} className=" text-center text-lg-start ">
								<h2 className="text-white pb-4 ">
									Can I have private therapy then transfer to the NHS?
								</h2>
								<p className="text-white">
									All UK based patients are entitled to NHS care. However, we
									appreciate that waiting lists for NHS care in the UK can be
									lengthy and that some people need advice and help for their
									facial palsy more quickly. We are therefore happy to see
									patients privately to get their rehabilitation started whilst
									they are on the waiting list for NHS care, then transfer their
									care over at the appropriate time.
								</p>
							</Col>
							<Col
								xs={{ span: 12, order: "first" }}
								lg={{ span: 7, order: "last" }}
								className="text-center mb-5 mb-lg-0"
							>
								<GatsbyImage
									image={data.nhsImg.localFile.childImageSharp.gatsbyImageData}
									alt={data.nhsImg.altText}
								/>
							</Col>
						</Row>
					</Container>
				</section>
				<section>
					<BgImage className="" image={pluginImage1}>
						<Container>
							<Row className="justify-content-start py-5 py-lg-7">
								<Col lg={6}>
									<div
										style={{ borderRadius: "32px" }}
										className="h-100 w-100 py-5 px-4 p-md-5 bg-white"
									>
										<h2 className=" text-dark-bg pb-4 ">
											Why can’t I go to a general therapist?
										</h2>
										<p className="">
											Facial rehabilitation is not taught at university so
											general therapists do not have specialist expertise in
											this area. General therapists often teach inappropriate
											exercises and use inappropriate treatments based on their
											knowledge of rehabilitation of the body which is
											completely different to the face.
										</p>
										<p className="">
											{" "}
											Specialist facial therapists have done additional
											postgraduate training in facial therapy to understand how
											to rehabilitate the face specifically.
										</p>
										<p className="">
											{" "}
											Most specialist facial therapists are members of{" "}
											<a
												target="_blank"
												rel="noreferrer"
												href="https://facialtherapyspecialists.com/"
											>
												Facial Therapy Specialists International
											</a>{" "}
											which is a specialist interest group for facial
											therapists. Our therapists are not only members of this
											group but also set it up and are on the committee!
										</p>
										<div className="text-center mt-4">
											<GatsbyImage
												className="w-50"
												image={
													data.ftsImg.localFile.childImageSharp.gatsbyImageData
												}
												alt={data.ftsImg.altText}
											/>
										</div>
									</div>
								</Col>
							</Row>
						</Container>
					</BgImage>
				</section>
				<section className="bg-primary py-5 py-lg-7">
					<Container>
						<Row>
							<Col className="text-center">
								<h2 className="text-white pb-4 ">
									When should I start facial therapy?
								</h2>

								<p className="text-white">
									To get the best recovery from your facial palsy:
								</p>
							</Col>
						</Row>
						<Row className="pt-5">
							<Col className="pb-5 pb-lg-0" lg={6}>
								<BsFillCheckCircleFill className="text-white fs-1 mb-3" />
								<p className="text-white ubuntu-medium">
									If you have had your facial palsy for 4 weeks and have had no
									recovery at all you should start specialist facial therapy.
								</p>
							</Col>
							<Col lg={6}>
								<BsFillCheckCircleFill className="text-white fs-1 mb-3" />
								<p className="text-white ubuntu-medium">
									If you are slowly recovering but are not fully recovered by
									6-8 weeks then you should start specialist facial therapy.
								</p>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="py-5 py-lg-7 position-relative mt-4">
					<div
						style={{ height: "400px" }}
						className="position-absolute d-none d-lg-block start-0 top-0 w-30  bg-light-grey"
					></div>
					<Container>
						<Row>
							<Col className=" text-center text-lg-start pb-5 pb-lg-0" lg={6}>
								<GatsbyImage
									style={{ height: "420px", width: "400px", maxWidth: "100%" }}
									image={data.nervImg.localFile.childImageSharp.gatsbyImageData}
									alt={data.nervImg.altText}
								/>
							</Col>
							<Col lg={6}>
								{" "}
								<h2 className=" text-dark-bg pb-4 ">
									What happened to my nerve?
								</h2>
								<p>
									When you get facial palsy, pressure (usually caused by
									swelling) squashes the facial nerve and stops signals getting
									through from the brain to the facial muscles.
								</p>
								<p>
									A bit like standing on a hosepipe stops the water getting
									through.
								</p>
								<p>
									The lack of signals getting to the muscles stops them working
									causing the face to drop. Once the pressure is removed the
									nerve signals can flow through again and the face can start to
									work again.
								</p>
								<p>
									The facial nerve is like an electrical cable connecting the
									brain and facial muscles. It has individual fibres (axons)
									which are wrapped up in bundles and these bundles are wrapped
									up together to make the nerve.
								</p>
								<p>Nerve recovery falls into 3 categories:</p>
								<ul className="">
									<li>Neuropraxia</li>
									<li>Axonotmesis</li>
									<li>Neurotmesis</li>
								</ul>
							</Col>
						</Row>
						<Row>
							<Col className="pt-5">
								<p>
									In the first category, neuropraxia, the nerve is temporarily
									‘bruised’. The nerve signals are temporarily blocked from
									getting through from your brain to your facial muscles. If you
									are in this category, you will notice improvement starting
									after just a few weeks and will gradually recover further
									until you are back to normal within 6-8 weeks. If you are
									recovering quickly, you don’t generally need any facial
									therapy although some patients like to have a session to help
									them with understanding their condition and advising them how
									to best manage symptoms.
								</p>
								<p>
									In the second category, axonotmesis, the nerve is more badly
									damaged. The amount of damage can vary from just a few of the
									individual nerve fibres (axons) being damaged right through to
									all the bundles of nerve fibres inside the nerve being
									damaged. However in this category the outer nerve covering is
									always intact.
								</p>
								<p>
									It is difficult to know how badly damaged your nerve has been
									but specialist facial therapy will give you the best chance of
									making a good recovery.
								</p>
								<p>
									It is possible that you won’t see any recovery at all until
									4-6 months after your facial palsy started. This is perfectly
									normal and is just the time it takes for the nerve to regrow
									from the point of injury back out to the facial muscles.
									Research shows that early facial therapy can minimise the risk
									of synkinesis and improve your facial movement from a much
									earlier stage of recovery.
								</p>
								<p>
									In the third category the whole nerve has been cut, usually
									due to trauma or a surgical procedure. If you are in this
									category, you will need a nerve repair followed by
									post-surgical facial therapy. We can advise further on
									surgical options if required.
								</p>
							</Col>
						</Row>
					</Container>
				</section>

				<section className="py-5 py-lg-7 bg-light-grey">
					<Container>
						<Row className="align-items-center gx-6">
							<Col lg={6} className=" text-center text-lg-start ">
								<h2 className="text-dark-bg pb-4 ">
									What should I do when my face is floppy?
								</h2>
								<p className="">
									If your face is floppy, it means the nerve isn’t working.
									Trying to exercise at this stage is like trying to turn a lamp
									on and off when it isn’t plugged in at the wall. Completely
									pointless.
								</p>
								<p className="ubuntu-bold">DO NOT EXERCISE AT THIS STAGE</p>
								<p className="">
									{" "}
									Just rest; protect your eye that won’t close; stretch the
									eyelid to stop it getting stiff and massage your face to keep
									the muscles supple. There is lots of advice in our acute
									facial palsy leaflet which you can download.
								</p>
								<Button
									as={Link}
									href="https://facial-rehabilitation-centre.rjmdigital.net/wp-content/uploads/2023/11/The-Facial-Rehabilitation-Centre-Advice-for-people-with-acute-facial-paralysis-1.pdf"
									className="px-3 me-md-4 w-100 w-md-auto text-white mt-3"
									variant="primary"
								>
									Download leaflet
								</Button>
							</Col>
							<Col
								xs={{ span: 12, order: "first" }}
								lg={{ span: 6, order: "last" }}
								className="text-center mb-5 mb-lg-0"
							>
								<GatsbyImage
									image={
										data.floppyImg.localFile.childImageSharp.gatsbyImageData
									}
									alt={data.floppyImg.altText}
								/>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="py-5 py-lg-7">
					<Container>
						<Row>
							<Col>
								{/* <p>
									<Link className="text-decoration-underline" to="/">
										There are also videos to show you what to do on our website.
									</Link>
								</p> */}
								<p>
									You may be given all sorts of advice about what will help.
									Please only follow the advice of your specialist facial
									therapist as incorrect treatments can cause harm and increase
									the likelihood of long-term problems.
								</p>
								<p>
									{" "}
									70% of people with facial palsy will get better{" "}
									<strong>without needing any treatment</strong>. Often people
									try different treatments and assume the treatment made them
									better. They tell their friends the treatment helped; they
									post it on the internet. They’re trying to help but in
									reality, this wasn’t the treatments helping it was just
									natural recovery.
								</p>
								<p>
									{" "}
									For the 70% of people these treatments won’t do any harm but
									for the other 30% these falsely “recommended” treatments could
									make things <strong>WORSE</strong>. This includes electrical
									stimulation which we do not recommend as it can increase the
									risk of synkinesis.
								</p>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="py-5 py-lg-7">
					<Container className="h-100">
						<Row>
							<Col>
								<h2 className="text-dark-bg text-center pb-5 ">
									What’s involved in specialist facial therapy?
								</h2>
							</Col>
						</Row>
						<Row className="h-100 g-5 align-items-center">
							<Col style={{ minHeight: "100%" }} lg={6} xl={4}>
								<div
									className=" d-flex align-items-center"
									style={{
										borderLeft: "3px solid #93C6D6 ",
										minHeight: "72px",
									}}
								>
									<p className="text-dark-bg ps-3 mb-0 pb-0 ubuntu-medium">
										Most patients will work alongside a specialist facial
										therapist for 12 months to 3 years
									</p>
								</div>
							</Col>
							<Col style={{ minHeight: "100%" }} lg={6} xl={4}>
								<div
									className=" d-flex align-items-center"
									style={{
										borderLeft: "3px solid #93C6D6 ",
										minHeight: "72px",
									}}
								>
									<p className="text-dark-bg ps-3 mb-0 pb-0 ubuntu-medium">
										Appointments aren’t too frequent because change is slow
									</p>
								</div>
							</Col>
							<Col style={{ minHeight: "100%" }} lg={6} xl={4}>
								<div
									className=" d-flex align-items-center"
									style={{
										borderLeft: "3px solid #93C6D6 ",
										minHeight: "72px",
									}}
								>
									<p className="text-dark-bg ps-3 mb-0 pb-0 ubuntu-medium">
										Patients learn to become “their own therapist” for the
										longer term
									</p>
								</div>
							</Col>
							<Col style={{ minHeight: "100%" }} lg={6} xl={4}>
								<div
									className=" d-flex align-items-center"
									style={{
										borderLeft: "3px solid #93C6D6 ",
										minHeight: "72px",
									}}
								>
									<p className="text-dark-bg ps-3  mb-0 pb-0 ubuntu-medium">
										Most treatment (90%+) is taught by the therapist and then
										done at home
									</p>
								</div>
							</Col>
							<Col style={{ minHeight: "100%" }} lg={6} xl={4}>
								<div
									className=" d-flex align-items-center"
									style={{
										borderLeft: "3px solid #93C6D6 ",
										minHeight: "72px",
									}}
								>
									<p className="text-dark-bg ps-3  mb-0 pb-0 ubuntu-medium">
										Once your brain learns something new and important to you it
										doesn’t forget it – improvements are maintained
									</p>
								</div>
							</Col>
							<Col style={{ minHeight: "100%" }} lg={6} xl={4}>
								<div
									style={{
										borderLeft: "3px solid #93C6D6 ",
										minHeight: "72px",
									}}
									className=" d-flex align-items-center"
								>
									<p className="text-dark-bg ps-3 mb-0 pb-0 ubuntu-medium">
										It’s never too late to start specialist therapy
									</p>
								</div>
							</Col>
							<Col style={{ minHeight: "100%" }} lg={6} xl={4}>
								<div
									style={{
										borderLeft: "3px solid #93C6D6 ",
										minHeight: "72px",
									}}
									className=" d-flex align-items-center"
								>
									<p className="text-dark-bg ps-3 mb-0 pb-0 ubuntu-medium">
										Everyone can be helped in some way
									</p>
								</div>
							</Col>
						</Row>
						<Row>
							<Col className="pt-5">
								<p>
									If you are interested in hearing about specialist facial
									therapy in more depth, seeing patient examples and learning
									more about how specialist facial therapy can help you please
									view our webinar:{" "}
									<a
										target="_blank"
										rel="noreferrer"
										className="text-decoration-underline"
										href="https://www.youtube.com/channel/UCh7SAOflAnkEFpBWBY-RYuQ"
									>
										Watch on YouTube
									</a>{" "}
								</p>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="bg-light-grey py-5 py-lg-7">
					<Container>
						<Row>
							<Col>
								<h2 className="text-dark-bg text-center pb-5 ">
									Can you help children with facial palsy?
								</h2>
								<p>
									Yes, we can. We are all experienced in paediatric facial
									therapy and have helped many children, of all ages, over the
									years. Children’s rehabilitation will change as they get older
									to suit their understanding and goals.
								</p>
								<p>
									We also work closely with the charity Facial Palsy UK and have
									been involved in creating information with them for parents,
									children and teenagers, as well as being involved in the
									development of a children’s book to help children and their
									friends better understand their facial palsy.
								</p>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="bg-light-grey py-5 pb-lg-7">
					<Container>
						<Row>
							<Col>
								<h2 className="text-dark-bg text-center pb-5 ">
									Can I have botulinum toxin injections for my facial palsy?
								</h2>
								<p>
									Yes, you can. However it is important to start facial therapy
									before you have any injections.
								</p>
								<p>
									Botulinum toxin injections can help when injected into key
									areas of synkinesis by a facial palsy specialist – this may be
									a doctor or therapist. Our therapists are all highly
									experienced in facial injecting. They have also carried out
									research and been invited to give international lectures on
									botulinum toxin injecting for facial palsy.
								</p>
								<p>
									The medication works by reducing the activity of{" "}
									<span className="ubuntu-bold">overactive</span> muscles back
									to a more ‘normal’ level. This allows you to work on
									relearning precise, correctly coordinated movements while the
									opposing muscles are ‘quieter’ for a few months. Injections
									are sometimes given to the unaffected side if it is also
									overactive.
								</p>
								<p>
									Botulinum toxin injections should not be given if you have not
									had a course of specialist facial therapy because injections
									alone cannot;
								</p>
								<ul>
									<li>
										Re-programme the brain with the correct address of the
										facial muscles
									</li>
									<li>
										Retrain the brain about correct movement and suppressing
										synkinetic movement for long term recovery
									</li>
									<li>Stretch tight, short, stiff muscles</li>
								</ul>
								<h3 className="text-dark-bg  pb-4 fs-4 text-center pt-4">
									Before and after botulinum toxin injections and specialist
									facial therapy
								</h3>
								<Row className="justify-content-center">
									<Col className="pb-4 pb-lg-0" md={4}>
										{" "}
										<GatsbyImage
											image={
												data.BAAImg1.localFile.childImageSharp.gatsbyImageData
											}
											alt={data.BAAImg1.altText}
										/>
									</Col>
									<Col className="pb-4 pb-lg-0" md={4}>
										{" "}
										<GatsbyImage
											image={
												data.BAAImg2.localFile.childImageSharp.gatsbyImageData
											}
											alt={data.BAAImg2.altText}
										/>
									</Col>
								</Row>
							</Col>
						</Row>
					</Container>
				</section>
				{/* <section className="py-5 py-lg-7 ">
					<Container>
						<Row className="align-items-center gx-6">
							<Col lg={6} className=" text-center text-lg-start ">
								<h2 className="text-dark-bg pb-4 ">
									Before And After Botulinum Toxin Injections And Specialist
									Facial Therapy
								</h2>
								<p className="">
									Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
									diam nonumy eirmod tempor invidunt ut labore et dolore magna
									aliquyam erat, sed diam voluptua. At vero eos et accusam et
									justo duo dolores et ea rebum.
								</p>

								<p className="">
									Stet clita kasd gub ergren, no sea takimata sanctus est Lorem
									ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur
									sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
									labore et dolore magna aliquyam erat, sed diam voluptua. At
									vero eos et accusam et justo duo dolores et ea rebum. Stet
									clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
									dolor sit amet.
								</p>
							</Col>
							<Col
								xs={{ span: 12 }}
								lg={{ span: 6 }}
								className="text-center pt-4 pt-lg-0 mb-5 mb-lg-0"
							>
								<Row>
									<Col className="pb-4 pb-lg-0" md={6}>
										{" "}
										<GatsbyImage
											image={
												data.BAAImg1.localFile.childImageSharp.gatsbyImageData
											}
											alt={data.BAAImg1.altText}
										/>
									</Col>
									<Col className="pb-4 pb-lg-0" md={6}>
										{" "}
										<GatsbyImage
											image={
												data.BAAImg2.localFile.childImageSharp.gatsbyImageData
											}
											alt={data.BAAImg2.altText}
										/>
									</Col>
								</Row>
							</Col>
						</Row>
					</Container>
				</section> */}
			</Layout>
		</>
	);
};

export default SFTPage;
